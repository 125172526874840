<template>
  <BrezelApp loading-animation="pulse">
    <template slot="logo">
      <div style="padding: 10px">
        <img src="/assets/logo.svg" style="width: 100%; max-height: 100px" alt="Logo">
      </div>
    </template>
    <template slot="spinner">
      <img src="/assets/512x512.png" width="128" height="128">
    </template>
    <template slot="loginLogo">
      <img src="/assets/logo.svg" :style="{ maxWidth: '100%', margin: '0 auto 26px auto', display: 'block' }" alt="logo">
    </template>
  </BrezelApp>
</template>

<script>
import BrezelApp from '@kibro/brezel-spa/src/components/App'

export default {
  name: 'App',
  components: {
    BrezelApp
  }
}
</script>

<style lang="scss">
</style>
